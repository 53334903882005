@tailwind base;
@tailwind components;
@tailwind utilities;
@import '@ng-select/ng-select/themes/default.theme.css';
@import '@angular/material/prebuilt-themes/azure-blue.css';

* {
  --webkit-box-sizing: border-box;
  --moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif !important;
}

body {
  @apply bg-app-bg;
}

html,
body {
  height: 100%;
}

/* modal.component.css */

/* Hide the default scrollbar */
::-webkit-scrollbar {
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Set the background color of the scrollbar track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; /* Set the color of the scrollbar thumb */
  border-radius: 0.188rem; /* Set the border radius of the scrollbar thumb */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Set the color of the scrollbar thumb on hover */
}

//
//input::placeholder {
//  font-size: 0.75rem;
//  @apply text-input-hint
//    /* Set the font size of the placeholder text */
//  ;
//}

//textarea::placeholder {
//  font-size: 0.75rem;
//  @apply text-input-hint;
//}

/* custom style ng-select */
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  font-size: 0.75rem !important;
  color: #d9edf0 !important;
}

.ng-select .ng-select-container {
  font-size: 0.75rem !important;
  border: 1px solid #e5eaeb !important;
  border-radius: 0.25rem !important;
  background-color: #f5fbfc !important;
  color: #111827 !important;
  padding: 0.5rem 0.5rem !important;
}

img {
  cursor: pointer;
}

.fal,
.far,
.fas,
.fad,
.fab,
.fa {
  font-family: 'Font Awesome 5 Pro', serif !important;
}

.mat-mdc-select-placeholder {
  color: #a5b8bb !important;
  font-weight: 400 !important;
}

.mat-mdc-form-field {
  font-size: 0.875rem !important;
  height: 2.5rem !important;
  font-weight: 500 !important;
  -webkit-font-smoothing: none !important;
  color: #242424 !important;
  outline: 1px solid #e5eaeb !important;
  background: #f5fbfc !important;
  border-radius: 0.25rem !important;
}

.mat-mdc-form-field > input {
  outline: none !important;
}

.mat-mdc-form-field > input::placeholder {
  color: #a5b8bb !important;
  font-weight: 400 !important;
}

.mat-mdc-text-field-wrapper {
  width: 100%;
  border-radius: 0.25rem !important;
  background-color: #f5fbfc !important;
}

.mat-mdc-text-field-wrapper:focus-within {
  border: 1px solid #a5b8bb !important;
}

.mdc-notched-outline {
  display: none !important;
}

.custom-icon {
  margin-left: auto; /* Align the icon to the right */
  color: red; /* Change the icon color */
}

.mat-mdc-text-field-wrapper {
  width: 100%;
  border-radius: 0.25rem !important;
  background-color: #f5fbfc !important;
  height: 2.5rem !important;
}

.mat-mdc-select {
  padding: 0 !important;
  background-color: #f5fbfc !important;
  border: none !important;
  color: #242424 !important;
  font-weight: 500 !important;
}

.mdc-text-field__input::placeholder {
  color: #a5b8bb !important;
  font-weight: 400 !important;
}

.mat-mdc-select:focus {
  border: none !important;
  outline: none !important;
  --tw-ring-color: #f5fbfc !important;
}

mat-select::ng-deep .mat-select-arrow {
  display: none; /* Hide the default arrow */
}

.mat-mdc-select-arrow {
  content: url('./assets/svg/arrow-down.svg');
  width: 75% !important;
  height: 75% !important;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled)
.mdc-text-field__input {
  caret-color: #111827 !important;
  --tw-ring-color: #e5eaeb !important;
  --tw-ring-offset-color: none !important;
  --tw-ring-shadow: none !important;
}

.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:enabled:indeterminate
~ .mdc-checkbox__background {
  border-color: #11484f !important;
  background-color: #11484f !important;
}

.mat-mdc-dialog-container {
  border-radius: 0.75rem !important;
  overflow: hidden !important;
}

.mat-mdc-dialog-content > :last-child {
  margin-bottom: 10px !important;
}

.mat-mdc-dialog-container .mat-mdc-dialog-title {
  font-size: 0.75rem !important;
  font-weight: 600 !important;
  color: #242424 !important;
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  display: none !important;
}

.mat-mdc-form-field-error {
  color: #f0614e !important;
  font-size: 0.75rem !important;
}

.mat-mdc-autocomplete-panel {
  color: #242424 !important;
  background-color: #f5fbfc !important;
  box-shadow: none !important;
  border: 1px solid #a5b8bb;
  border-radius: 0.25rem !important;
  font-size: 0.75rem !important;
  padding: 0 !important;
}


.mat-mdc-form-field-infix {
  padding: 0.5rem 0 !important;
}

.mat-mdc-select-panel {
  color: #242424 !important;
  background-color: #f5fbfc !important;
  box-shadow: none !important;
  border: 1px solid #a5b8bb;
  border-radius: 0.25rem !important;
  font-size: 0.875rem !important;
  padding: 0 !important;
}

.error {
  outline: none !important;
  --tw-ring-color: none !important;
  border: 1px solid #f7cad0 !important;
  border-radius: 0.25rem !important;
}

::ng-deep .error .mat-mdc-text-field-wrapper {
  width: 100%;
  border-radius: 0.25rem !important;
  background-color: #fffafb !important;
  height: 2rem !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f5fbfc inset !important;
}

textarea::placeholder {
  font-size: 0.75rem !important;
  color: #a5b8bb !important;
}

textarea:focus {
  --tw-ring-color: #e5eaeb !important;
}

.mat-date-range-input-wrapper input {
  font-weight: 500 !important;
  font-size: 0.75rem !important;
}

.mat-date-range-input-wrapper input::placeholder {
  font-size: 0.75rem !important;
  color: #a5b8bb !important;
  font-weight: 400 !important;
}

.mat-date-range-input-separator {
  color: #a5b8bb !important;
}


img:hover {
  cursor: pointer;
}

.mat-mdc-dialog-surface {
  background-color: white !important;
}

.tel-form {
  border: none !important;
  outline: none !important;
  background-color: #ffffff !important;
  border-radius: 0.25rem !important;
  @apply space-x-2 bg-white;
}

.tel-form input {
  --tw-ring-color: none !important;
}
.mat-mdc-select-placeholder {
  color: #a5b8bb !important;
  font-weight: 400 !important;
  font-size: 0.875rem !important;
}

.filter .mat-mdc-select-placeholder {
  color: #a5b8bb !important;
  font-weight: 400 !important;
  font-size: 0.75rem !important;
}

.filter {
  font-size: 0.75rem !important;
  height: 2rem !important;
  font-weight: 500 !important;
  -webkit-font-smoothing: none !important;
  color: #242424 !important;
  outline: 1px solid #e5eaeb !important;
  background: #f5fbfc !important;
  border-radius: 0.25rem !important;
}

.filter .mat-mdc-text-field-wrapper {
  border-radius: 0.25rem !important;
  background-color: #f5fbfc !important;
  height: 2rem !important;
}

.filter .mat-mdc-form-field-infix {
  padding: 0.25rem 0 !important;
}

.filter .mat-mdc-icon-button {
  margin-bottom: 1rem;
}

